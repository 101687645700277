import React, { useState } from 'react';
import { Layout, Form, Input, Button, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../api/api';
import '../styles/App.less';
import '../styles/App.css';

import logoNext from '../assets/logo/logo-next.png';
import backgroundImage from '../assets/images/bg-2.jpg'; // Nova imagem de fundo

const { Content } = Layout;

const NextSignupForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Função para formatar o número de celular
  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length <= 10) {
      return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      values.phone = formatPhoneNumber(values.phone);
      const response = await api.post('/choose_at', values);
      if (response.data.success) {
        window.location.href = '/Success';
      }
    } catch (error) {
      console.error('Erro no cadastro:', error);
      setLoading(false);
    }
  };

  const checkUsernameAvailability = async (rule, value) => {
    try {
      const checkUsernameResponse = await api.get(`/check_username?username=${value}`);
      if (!checkUsernameResponse.data.available) {
        return Promise.reject('Esse @ já está em uso. Por favor, escolha outro.');
      }
    } catch (error) {
      console.error('Erro ao verificar a disponibilidade do @:', error);
    }
    return Promise.resolve();
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="next-form-container">
          <div className="next-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="logo-container">
              <img className="logo-next" src={logoNext} alt="Logo Next" />
            </div>
            <div className="text-right w-100">
              <p className="tipzar-next">tipzar | Next</p>
            </div>
          </div>
          <div className="text-center w-50 mt-5">
            <img className="success-image" src={require('../assets/images/check-icon.png')} alt="" />
            <h2 className='text-black'>Obrigado!</h2>
            <p className='text-gray text-medium'>Nosso time já recebeu o seu cadastro, vamos analisar e nos falamos em breve!</p>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default NextSignupForm;
