import React, { useState } from 'react';
import { Layout, Form, Input, Button, Typography, Image } from 'antd';
import axios from 'axios';
import '../styles/App.less';
import '../styles/App.css';

import backgroundImage from '../assets/images/bg-1.jpg'; // Importe a imagem de fundo

const { Content } = Layout;
const { Title } = Typography;

const Success = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post('http://tipzar.debvell.com.br/api/choose_at', values);
      if (response.data.success) {
        // Redirecionar para a tela PreSignupSuccess
        window.location.href = '/Success';
      }
    } catch (error) {
      console.error('Erro no cadastro:', error);
      setLoading(false);
    }
  };

  const handleRedirecionar = () => {
    window.open('http://next.tipzar.io', '_blank');
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="form-container">
          <div className="half-width left-side" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="right-content">
              <div className="logo">
                <img className="logo-image" src={require('../assets/logo/logo-tipzar.png')} alt="Logo Tipzar" />
              </div>

              <div className="text-container">
                <h2>Quem é você na fila do Pão?</h2>
                <p className='subtitle'>Preencha os dados ao lado para garantir<br></br>o seu @ dentro da Tipzar.</p>
              </div>
              <div className="bottom-text">
                Fun&Monetize<br />tipzar.io
              </div>
            </div>
          </div>
          <div className="half-width right-side">
            <div className="right-content">
                <div className="text-container text-center">
                  <img className="success-image" src={require('../assets/images/check-icon.png')} alt="" />
                  <h2 className='text-black'>Obrigado!</h2>
                  <p className='text-gray text-medium'>O seu @ é único e exclusivo!</p>
                  <p className='text-gray text-medium'>A nossa Rede Social inicialmente será exclusiva para pessoas que possuem convites.</p>
                  <p className='text-gray text-medium'>Assim que liberarmos a primeira versão do nosso App você receberá um convite para ingressar na mais nova rede social Tipzar.</p>
                </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Success;
