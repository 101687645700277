import React, { useState } from 'react';
import { Layout, Form, Input, Button, Typography, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../api/api'; // Importe a instância do Axios

import '../styles/App.less';
import '../styles/App.css';

import backgroundImage from '../assets/images/bg-1.jpg'; // Importe a imagem de fundo
import btnIcon from '../assets/images/btn-icon.png'; // Importe a imagem de fundo

const { Content } = Layout;
const { Title } = Typography;

const SignupForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Função para formatar o número de celular
  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    if (cleanedValue.length <= 10) {
      return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); // Formato (99) 9999-9999
    }
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formato (99) 99999-9999
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      // Formate o número de celular antes de enviar para o servidor
      values.phone = formatPhoneNumber(values.phone);
      const response = await api.post('/api.php?do=select_username', values); // Usando a instância do Axios
      if (response.data.success) {
        window.location.href = '/Success';
      }
    } catch (error) {
      // Somente para testes
      window.location.href = '/Success';
      console.error('Erro no cadastro:', error);
      setLoading(false);
    }

  };

  const checkUsernameAvailability = async (rule, value) => {
    try {
      const checkUsernameResponse = await api.post('/api.php?do=/check_username', { 'username': value }); // Usando a instância do Axios
      
      if (!checkUsernameResponse.data.available) {
        return Promise.reject('Esse @ já está em uso. Por favor, escolha outro.');
      }
    } catch (error) {
      console.error('Erro ao verificar a disponibilidade do @:', error);
    }

    return Promise.resolve();
  };

  const handleRedirecionar = () => {
    window.location.href = '/NextSignupForm';
    // window.open('http://next.tipzar.io', '_blank');
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="form-container">
          <div className="half-width left-side" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="right-content">
              <div className="logo">
                <img className="logo-image" src={require('../assets/logo/logo-tipzar.png')} alt="Logo Tipzar" />
              </div>

              <div className="text-container">
                <h2>Quem é você na fila do Pão?</h2>
                <p className='subtitle'>Preencha os dados ao lado para garantir<br></br>o seu @ dentro da Tipzar.</p>
              </div>
              <div className="bottom-text">
                Fun&Monetize<br />tipzar.io
              </div>
            </div>
          </div>
          <div className="half-width right-side">
            <div className="form-content">
              <Form
                form={form}
                onFinish={handleFormSubmit}
                layout="vertical"
                className="signup-form"
              >
                {/* Campos do formulário */}
                <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Informe seu nome' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="E-mail" name="email" rules={[{ required: true, type: 'email', message: 'Informe um e-mail válido' }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Celular"
                  name="phone"
                  rules={[
                    { required: true, message: 'Informe seu celular' },
                    {
                      validator: (_, value) => {
                        // Validar o formato do telefone
                        if (!/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/.test(value)) {
                          return Promise.reject('Formato de telefone inválido');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input onBlur={(e) => form.setFieldsValue({ phone: formatPhoneNumber(e.target.value) })} maxLength={13} />
                </Form.Item>
                <Form.Item
                  label="Seu @"
                  name="username"
                  rules={[
                    { required: true, message: 'Informe seu @' },
                    { validator: checkUsernameAvailability, trigger: 'onBlur' },
                  ]}
                >
                  <Input addonBefore="@" />
                </Form.Item>
                <Form.Item className='btn-group'>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {loading ? <LoadingOutlined /> : 'Enviar'}
                  </Button>
                  <Button type="default" onClick={handleRedirecionar}>
                    <Image src={btnIcon} preview={false} className="btn-icon"/>
                    Quero ser um Next Creator
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default SignupForm;
