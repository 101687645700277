import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignupForm from './screens/SignupForm'; // Importe sua tela inicial
import Success from './screens/Success'; // Importe sua tela de sucesso
import NextSignupForm from './screens/NextSignupForm'; // Importe sua tela inicial
import NextSuccess from './screens/NextSuccess'; // Importe sua tela de sucesso

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<SignupForm/>} />
        <Route path="/success" element={<Success/>} />
        <Route exact path="/nextSignupForm" element={<NextSignupForm/>} />
        <Route path="/nextSuccess" element={<NextSuccess/>} />
      </Routes>
    </Router>
  );
};

export default App;
