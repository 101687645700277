import React, { useState } from 'react';
import { Layout, Form, Input, Button, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../api/api';
import '../styles/App.less';
import '../styles/App.css';

import logoNext from '../assets/logo/logo-next.png';
import backgroundImage from '../assets/images/bg-2.jpg'; // Nova imagem de fundo

const { Content } = Layout;

const NextSignupForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Função para formatar o número de celular
  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length <= 10) {
      return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      // Formate o número de celular antes de enviar para o servidor
      values.phone = formatPhoneNumber(values.phone);
      const response = await api.post('/api.php?do=select_username_next', values); // Usando a instância do Axios
      if (response.data.success) {
        window.location.href = '/NextSuccess';
      }
    } catch (error) {
      // Somente para testes
      window.location.href = '/NextSuccess';
      console.error('Erro no cadastro:', error);
      setLoading(false);
    }
  };

  const checkUsernameAvailability = async (rule, value) => {
    try {
      const checkUsernameResponse = await api.post('/api.php?do=/check_username', { 'username': value }); // Usando a instância do Axios
      
      if (!checkUsernameResponse.data.available) {
        return Promise.reject('Esse @ já está em uso. Por favor, escolha outro.');
      }
    } catch (error) {
      console.error('Erro ao verificar a disponibilidade do @:', error);
    }

    return Promise.resolve();
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="next-form-container">
          <div className="next-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="logo-container">
              <img className="logo-next" src={logoNext} alt="Logo Next" />
            </div>
            <div className="text-right w-100">
              <p className="tipzar-next">tipzar | Next</p>
            </div>
          </div>
          <div className="next-form-content">
            <Form
              form={form}
              onFinish={handleFormSubmit}
              layout="vertical"
              className="signup-form"
            >
              <h2 className='mt-5'>Preencha o cadastro abaixo para participar da seleção do Tipzar Next.</h2>
              <p class="text-gray mb-3">Seja um criador de conteúdo da mais nova rede social tenha vários benefícios</p>
              <div className="form-row">
                <div className="form-item-half">
                  <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Informe seu nome' }]}>
                    <Input maxLength={50}/>
                  </Form.Item>
                </div>
                <div className="form-item-half">
                  <Form.Item label="E-mail" name="email" rules={[{ required: true, type: 'email', message: 'Informe um e-mail válido' }]}>
                    <Input maxLength={50}/>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row">
                <div className="form-item-half">
                  <Form.Item
                    label="Celular"
                    name="phone"
                    rules={[
                      { required: true, message: 'Informe seu celular' },
                      {
                        validator: (_, value) => {
                          if (!/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/.test(value)) {
                            return Promise.reject('Formato de telefone inválido');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input onBlur={(e) => form.setFieldsValue({ phone: formatPhoneNumber(e.target.value) })} maxLength={13} />
                  </Form.Item>
                </div>
                <div className="form-item-half">
                  <Form.Item
                    label="Seu @"
                    name="username"
                    rules={[
                      { required: true, message: 'Informe seu @' },
                      { validator: checkUsernameAvailability, trigger: 'onBlur' },
                    ]}
                  >
                    <Input addonBefore="@" maxLength={30}/>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row">
                <div className="form-item-half">
                  <Form.Item
                    label="Instagram"
                    name="instagram"
                    rules={[{ required: true, message: 'Informe seu Instagram' }]}
                    maxLength={30}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="form-item-half">
                  <Form.Item
                    label="Nicho de atuação"
                    name="niche"
                    rules={[{ required: true, message: 'Informe seu nicho de atuação' }]}
                    maxLength={50}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                label="Descreva como você enxerga a produção de conteúdo na sua área de atuação"
                name="opinion"
                rules={[{ required: true, message: 'Informe sua opinião' }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label="Por que devemos escolher você para ser um Tipzar Creator?"
                name="why"
                rules={[{ required: true, message: 'Informe o motivo' }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label="Você faria uma Live com o nosso time, caso fosse selecionada?"
                name="live"
                rules={[{ required: true, message: 'Informe sua escolha' }]}
              >
                <Checkbox.Group options={['Sim', 'Não']} />
              </Form.Item>
              <Form.Item
                label="Ao ser selecionado para fazer parte do Tipzar Next, você receberá um e-mail contendo quais serão os próximos passos. Nesse e-mail iremos detalhar quais serão seus benefícios exclusivos e o seus deveres para se tornar um Tipzar Next e também como você poderá evoluir para um Tipzar Creator, nosso grupo de elite de criadores. Você está comprometido com essa seleção?"
                name="accept"
                rules={[{ required: true, message: 'Informe sua escolha' }]}
              >
                <Checkbox.Group options={['Sim', 'Não']} />
              </Form.Item>
              <Form.Item className="btn-group text-center w-100">
                <Button type="primary" htmlType="submit" loading={loading} className="w-25">
                  {loading ? <LoadingOutlined /> : 'Enviar'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default NextSignupForm;
